import { DataSource } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { OperationObserverService } from '@x/common/operation';
import { ResponsiveService } from '@x/common/responsive';
import { InfiniteScrollDataViewConfig } from '@x/ecommerce-shop/app/core/shop-scroll/services/infinite-scroll-factory.service';
import { InfiniteScrollService } from '@x/ecommerce-shop/app/core/shop-scroll/services/infinite-scroll.service';
import { IShopCollection } from '@x/ecommerce/shop-client';
import { BehaviorSubject, combineLatest, Observable, Subscription, switchMap } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';

export interface DataGridRow<T = any> {
  id: string;
  items: T[];
}

export class InfiniteScrollDataView<T = any> extends DataSource<DataGridRow<T>> {
  private readonly _viewport$ = new BehaviorSubject<CdkVirtualScrollViewport | null>(null);
  private readonly _itemSize$ = new BehaviorSubject<number>(500);
  private readonly _subscription = new Subscription();
  private _pageIndex = 0;
  private _cachedData: T[] = [];
  private _totalItemsCount = 0;
  private _adCount = 0;
  private _dataStream$ = new BehaviorSubject<T[]>(this._cachedData);
  private _operationQ$ = this.operationService.createQueue();
  private _operationState$ = this._operationQ$.observe();

  private readonly _columns$: Observable<number> = this.responsiveService.observeBreakpoints().pipe(
    map(({ size }) => {
      const { defaultColumns, breakPoints } = this.config.columns;

      if (!size || !breakPoints) return defaultColumns;

      if (Object.keys(breakPoints).includes(size)) return breakPoints[size];

      return defaultColumns;
    }),
    shareReplay(),
  );

  private spilledOverItems: T[] = [];

  set incrementAdCount(count: number) {
    this._adCount += count;
  }

  set adCount(count: number) {
    this._adCount = count;
  }

  get adCount(): number {
    return this._adCount;
  }

  get dataStream$(): Observable<T[]> {
    return this._dataStream$.asObservable();
  }

  set itemSize(size: number) {
    this._itemSize$.next(size);
  }

  get itemSize() {
    return this._itemSize$.value;
  }

  get operationState$() {
    return this._operationState$;
  }

  get operationQLoading(): boolean {
    return this._operationQ$.isLoading;
  }

  get totalItemsCount(): number {
    return this._totalItemsCount;
  }

  set pageIndex(index: number) {
    this._pageIndex = index;
  }
  get pageIndex(): number {
    return this._pageIndex;
  }

  get cachedData(): T[] {
    return this._cachedData;
  }

  get columns(): number {
    return this.config.columns.defaultColumns;
  }

  get columns$(): Observable<number> {
    return this._columns$;
  }

  get columnClass$(): Observable<string> {
    return this.columns$.pipe(map((cols) => `col-${12 / cols}`));
  }

  set viewport(viewport: CdkVirtualScrollViewport | null) {
    this._viewport$.next(viewport);
  }

  get viewport(): CdkVirtualScrollViewport | null {
    return this._viewport$.value;
  }

  connect(): Observable<DataGridRow<T>[]> {
    // init stream
    this._subscription.add(
      this.config.initTrigger$.subscribe((scrollPositionData) => {
        this.resetView();

        this._operationQ$.queue(
          this.config
            .fetchCollection$(0, scrollPositionData?.count)
            .pipe(tap((collection) => this.initDataStream(collection))),
          { label: 'Initialize' },
        );
      }),
    );

    // add to queue
    this._subscription.add(
      this._viewport$
        .pipe(
          filter(Boolean),
          switchMap((vp) => vp.scrolledIndexChange),
        )
        .subscribe(() => {
          if (!this.viewport) return;

          if (this.operationQLoading) return;

          if (this.cachedData.length - this.adCount === this.totalItemsCount) return;

          if (this.infiniteScrollService.canNextPage(this.viewport, this.itemSize)) {
            this.pageIndex++;

            this._operationQ$.queue(
              this.config
                .fetchCollection$(this.pageIndex)
                .pipe(tap(({ items }) => this.updateDataStream(items))),
            );
          }
        }),
    );

    // return stream
    return combineLatest([this._dataStream$, this._columns$]).pipe(
      map(([items, cols]) => this.makeDataGridRows(items, cols)),
    );
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  constructor(
    public config: InfiniteScrollDataViewConfig<T>,
    private responsiveService: ResponsiveService,
    private operationService: OperationObserverService,
    private infiniteScrollService: InfiniteScrollService,
  ) {
    super();
  }

  private initDataStream({ totalItemsCount, items }: IShopCollection<T>) {
    this._pageIndex = Math.ceil((items.length - this.adCount) / this.config.pageSize) - 1;
    this._totalItemsCount = totalItemsCount;
    this._cachedData = [];
    this.spilledOverItems = [];
    this.updateDataStream(items);
  }

  private resetView() {
    this._pageIndex = 0;
    this._adCount = 0;
    this._totalItemsCount = 0;
    this._cachedData = [];
    this._dataStream$.next(this._cachedData);
  }

  private makeDataGridRows(items: T[], columns: number): DataGridRow<T>[] {
    return items.reduce((rows: DataGridRow<T>[], item: T, i: number) => {
      const c = i % columns;
      const r = Math.floor(i / columns);
      if (!rows[r]) rows[r] = { id: '', items: [] };
      rows[r].items[c] = item;
      rows[r].id += i;
      return rows;
    }, [] as DataGridRow<T>[]);
  }

  private updateDataStream(items: T[]) {
    const combinedItems = [...this.spilledOverItems, ...items];
    this.spilledOverItems = [];

    if (items.length > this.config.pageSize) {
      const spilledItemCount = combinedItems.length % this.config.pageSize;

      if (spilledItemCount) {
        this.spilledOverItems = [...combinedItems.splice(-spilledItemCount, spilledItemCount)];
      }
    }

    this._cachedData = [...this._cachedData, ...combinedItems];
    this._dataStream$.next(this._cachedData);
  }

  removeFromList(id: number) {
    const filteredList = this._cachedData.filter((item) => {
      return (item as any).hasOwnProperty('id') && (item as any).id !== id;
    });

    if (filteredList.length === this._cachedData.length) return;

    this._totalItemsCount--;
    this._cachedData = [...filteredList];
    this._dataStream$.next(this._cachedData);
  }
}
