<ng-content select="[xHeader]"></ng-content>

<cdk-virtual-scroll-viewport
  [itemSize]="dataView.itemSize"
  appendOnly
  class="product-list-viewport"
  scrollWindow
  [maxBufferPx]="dataView.itemSize"
>
  <div
    class="product-list-row row pb-5 pb-lg-3"
    #PRODUCT_ROW
    *cdkVirtualFor="let row of dataView; trackBy: 'id' | trackBy"
  >
    <ng-container *ngFor="let item of row.items; trackBy: 'id' | trackBy">
      <ng-container
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    </ng-container>
  </div>
</cdk-virtual-scroll-viewport>

<ng-container *ngIf="dataView.operationState$ | async as operation" [xOperation]="operation">
  <ng-container *xOperationLoading [ngTemplateOutlet]="loadTemplate"></ng-container>
  <ng-container *xOperationResult>
    <ng-container
      *ngIf="noDataTemplate && dataView.totalItemsCount === 0"
      [ngTemplateOutlet]="noDataTemplate"
    ></ng-container>
  </ng-container>
</ng-container>
