import { Injectable } from '@angular/core';
import { OperationObserverService } from '@x/common/operation';
import { DeviceSizes, ResponsiveService } from '@x/common/responsive';
import { InfiniteScrollService } from '@x/ecommerce-shop/app/core/shop-scroll/services/infinite-scroll.service';
import { ScrollPositionData } from '@x/ecommerce-shop/app/core/shop-scroll/services/shop-scroll.service';
import { InfiniteScrollDataView } from '@x/ecommerce-shop/app/core/shop-scroll/views/infinite-scroll-data-view';
import { IShopCollection } from '@x/ecommerce/shop-client';
import { Observable } from 'rxjs';

export interface InfiniteScrollDataViewColumns {
  defaultColumns: number;
  breakPoints?: {
    [key in DeviceSizes]: number;
  };
}

export interface InfiniteScrollDataViewConfig<T> {
  columns: InfiniteScrollDataViewColumns;
  initTrigger$: Observable<ScrollPositionData | null>;
  fetchCollection$: (index: number, size?: number) => Observable<IShopCollection<T>>;
  pageSize: number;
  initialPageSize?: number;
}

@Injectable()
export class InfiniteScrollFactoryService {
  constructor(
    private responsiveService: ResponsiveService,
    private operationService: OperationObserverService,
    private infiniteScrollService: InfiniteScrollService,
  ) {}

  createView<T>(config: InfiniteScrollDataViewConfig<T>): InfiniteScrollDataView {
    return new InfiniteScrollDataView<T>(
      config,
      this.responsiveService,
      this.operationService,
      this.infiniteScrollService,
    );
  }
}
