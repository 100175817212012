import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { WindowRef } from '@x/common/browser';

@Injectable()
export class InfiniteScrollService {
  constructor(private windowRef: WindowRef) {}

  canNextPage(viewport: CdkVirtualScrollViewport, itemSize: number): boolean {
    const viewportHeight = viewport.getViewportSize();
    const scrollOffset = viewport.measureScrollOffset();
    const totalContentHeight = viewport.getDataLength() * itemSize;
    const endOffset = totalContentHeight - (scrollOffset + viewportHeight);

    return scrollOffset > 0 && endOffset <= this.windowRef.instance.innerHeight;
  }
}
