import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { fadeInOut } from '@x/common/animations';
import { InfiniteScrollDataView } from '@x/ecommerce-shop/app/core/shop-scroll/views/infinite-scroll-data-view';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'x-infinite-scroll-collection',
  templateUrl: './infinite-scroll-collection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-infinite-scroll-collection' },
  animations: [fadeInOut],
})
export class InfiniteScrollCollectionComponent implements AfterViewInit, OnDestroy {
  private resizeObserver: ResizeObserver;

  @ViewChild(CdkVirtualScrollViewport) set viewport(vp: CdkVirtualScrollViewport) {
    if (!vp) return;

    if (this.dataView.viewport) return;

    this.dataView.viewport = vp;
  }

  @ViewChild('PRODUCT_ROW', { static: false }) set productRow(row: ElementRef) {
    if (!row) return;

    this.resizeObserver.observe(row.nativeElement);
  }

  @Input({ required: true }) dataView: InfiniteScrollDataView;

  @Input({ required: true }) itemTemplate: TemplateRef<any>;
  @Input({ required: true }) loadTemplate: TemplateRef<any>;

  @Input() noDataTemplate: TemplateRef<any>;

  ghostArray$: Observable<any[]>;

  ngAfterViewInit(): void {
    this.ghostArray$ = this.dataView.columns$.pipe(map((cols) => new Array(cols)));
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const rowHeight = entry.target.getBoundingClientRect().height;
        if (rowHeight && rowHeight !== this.dataView.itemSize) {
          this.dataView.itemSize = rowHeight;
          this.dataView.viewport?.checkViewportSize();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
