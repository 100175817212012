import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollableWindow,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdComponent } from '@x/common/ads/components/ad/ad.component';
import { OperationModule } from '@x/common/operation';
import { ProductModule } from '@x/ecommerce-shop/app/core/product/product.module';
import { InfiniteScrollFactoryService } from '@x/ecommerce-shop/app/core/shop-scroll/services/infinite-scroll-factory.service';
import { InfiniteScrollService } from '@x/ecommerce-shop/app/core/shop-scroll/services/infinite-scroll.service';
import { ShopScrollService } from '@x/ecommerce-shop/app/core/shop-scroll/services/shop-scroll.service';
import { GhostProductListComponent } from '@x/ecommerce-shop/app/core/shop-ui/components/ghost-loading/ghost-product-list/ghost-product-list.component';
import { UtilsModule } from '@x/ecommerce-shop/app/core/utils/utils.module';
import { WishlistModule } from '@x/ecommerce-shop/app/core/whishlist/wishlist.module';
import { GhostProductListingModule } from 'apps/ecommerce-shop/src/app/core/shop-ui/components/ghost-loading/ghost-product-card';
import { InfiniteScrollCollectionComponent } from './components/infinite-scroll-collection/infinite-scroll-collection.component';

@NgModule({
  providers: [ShopScrollService, InfiniteScrollFactoryService, InfiniteScrollService],
  declarations: [InfiniteScrollCollectionComponent],
  imports: [
    CommonModule,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollableWindow,
    CdkVirtualScrollViewport,
    OperationModule,
    GhostProductListingModule,
    WishlistModule,
    UtilsModule,
    ProductModule,
    AdComponent,
    GhostProductListComponent,
  ],
  exports: [InfiniteScrollCollectionComponent],
})
export class ShopScrollModule {}
